import React, { useState, useRef, useEffect } from 'react';

import CalendarioAgendamento from './calendarioAgendamento';
import {isMobile} from 'react-device-detect';
import MaskWithValidation from '../../components/maskInput'
import { ValidationForm, SelectGroup, TextInput } from 'react-bootstrap4-form-validation';
import {
    Card,
    CardBody,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';

import api  from '../../services/api'
import  QRCode from 'react-qr-code'
import LOGO_CA from './../../assets/logo-ca.png'
import {toPng } from 'html-to-image';
import { format  } from 'date-fns'
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import { NotificationContainer } from 'react-notifications';

export default function Agendamento( props) {
    const ref = useRef(null)
    const [loading, setLoading]         = useState(false)
    const [loadingPost, setLoadingPost] = useState(false)
    const [step, setStep]               = useState(1)

    const [service, setService]         = useState('')
    const [description, setDescription] = useState('') 
    const [date, setDate]               = useState(null)
    const [hour, setHour]               = useState('')
    const [name, setName]               = useState('')
    const [phone, setPhone]             = useState('')
    const [email, setEmail]             = useState('')
    const [observacao, setObservacao]   = useState('')

    const [dataHours, setDataHours]     = useState([])
    const [tipo, setTipo]               = useState([])

    const [dateFormat, setDateFormat]   = useState('')

    const setOriginalDate = (_date) => {
        setDateFormat(format(new Date(_date),'dd/MM/yyyy'))
    }

    const getData = async() => {
        setLoading(true)
        await api.get('/getschedulingDataS')
                 .then(response => {
                    if (response.ok) {
                        setTipo(response.data)
                        setLoading(false)
                    }
                  }).catch((error) => {

                  })
    }

    const handleService = (id, text) => {
        setService(id)
        setDescription(text)

        if (date !== null)
            handleDate(date, id)
    } 
    
    const handleDate = async(_date, _service) => {
        setDate(_date)

        let _data = { data    : _date,
                      service : _service === null ? service : _service}
    
        await api.post('/getscheduling',_data)
                 .then(response => {
                    if (response.ok) {
                        setDataHours(response.data || [])
                    }
                  }).catch((error) => {

                  })

    }

    const handleSubmit = async() => {   
        try {                    
            toPng(ref.current)
                       .then(async function (dataUrl) {
                            let _data = { service,
                                          date,
                                          hour,
                                          name,
                                          phone,
                                          email,
                                          observacao,
                                          idcompany : 2,
                                          imageBase64 : dataUrl}
     
                        await api.post('/scheduling',_data)
                                 .then(response => {      
                                    if (response.ok) {
                                        if (response.data.status === 500)
                                            NotificationManager.error(response.data.message, 'Erro!', 5000)
                                        else 
                                            setStep(5)
                                        setLoadingPost(false) 
                                    }
                                }).catch((error) => {
                                    console.log(error)
                                })
                        })
                        .catch(function (error) {
                            console.error('oops, something went wrong!', error);
                        });
        
        } catch(error)  {
            console.log(error)
        }
    }

    const handleStep = (_step, back) => {
        if (step >= 5)
            return 

        if (step === 1) {
            if (service === '')
                return
        }
        
        if (step === 2) {
            if ((date === null) && (!back)) {
                document.querySelector('.fc-media-screen').style.border = '1px solid #dc3545'
                return
            }
            document.querySelector('.fc-media-screen').style.border = '0'
        }

        if (step === 3) {
            if ((hour === '') && (!back))
                return
        }

        if (step === 4) {
            if (((name === '') || (phone === '')) && (!back))
                return
            else {
                if ((name !== '') && (phone !== '') && (hour !== '') && (date !== '') && (service !== '')) {
                    setLoadingPost(true) 
                    handleSubmit()
                    return
                }
            }
        }

        setStep(_step)
    }

    useEffect(() => {
        getData()
    },[])

    return (<div className="margin-box">
                <div className='box-title'>   
                    <div className="logo">
                        <img src={LOGO_CA} alt='logo'/>
                    </div>
                    <div className="txt-title">
                        <span>Agendamento Online</span>
                    </div>
                </div>
                <NotificationContainer />
                <ValidationForm name="formWizard" onSubmit={handleSubmit} setFocusOnError={true}> 
                    <Card className="card-default" >
                        <CardBody className='bottom-50'>
                            <Nav pills justified={true}>
                                {step === 1 || !isMobile?
                                    <NavItem>
                                        <NavLink className={step === 1 ? "active" : ""} onClick={e => handleStep(1,false)}>
                                            <h4 className="text-left my-3">
                                                1. Serviço à Agendar
                                                <br />
                                                <small>Escolha entre os serviços abaixo qual você deseja agendar.</small>
                                            </h4>
                                        </NavLink>
                                    </NavItem>
                                : ''}
                                {step === 2 || !isMobile ?
                                    <NavItem >
                                        <NavLink className={step === 2 ? "active" : ""} onClick={e => handleStep(2,false)}>
                                            <h4 className="text-left my-3">
                                                2. Data do Agendamento
                                                <br />
                                                <small>Escolha entre as datas disponíveis para o seu agendamento.</small>
                                            </h4>
                                        </NavLink>
                                    </NavItem>
                                : ''    
                                }
                                {step === 3 || !isMobile ?
                                    <NavItem >
                                        <NavLink className={step === 3 ? "active" : ""} onClick={e => handleStep(3,false)}>
                                            <h4 className="text-left my-3">
                                                3. Horários
                                                <br />
                                                <small>Escolha entre os horários disponíveis para o seu agendamento</small>
                                            </h4>
                                        </NavLink>
                                    </NavItem>
                                : '' }
                                {step === 4 || !isMobile ?
                                    <NavItem >
                                        <NavLink className={step === 4 ? "active" : ""} onClick={e => handleStep(4,false)}>
                                            <h4 className="text-left my-3">
                                                4. Dados
                                                <br />
                                                <small>Informe seus dados para finalizar o agendamento.</small>
                                            </h4>
                                        </NavLink>
                                    </NavItem>
                                : ''}
                                {step === 5 || !isMobile ?
                                    <NavItem >
                                        <NavLink  className={step === 5 ? "active" : ""} onClick={e => handleStep(5,false)} >
                                            <h4 className="text-left my-3">
                                                5. Comprovante
                                                <br />
                                                <small>Comprovante de Agendamento</small>
                                            </h4>
                                        </NavLink>
                                    </NavItem>
                                : ""}
                            </Nav>
                       
                            <TabContent activeTab={step}>
                                <TabPane id="tabPane1" tabId={1}>
                                    {loading ? 
                                        <div style={{display:"flex", alignItems:"center", justifyContent:"center", width:"100%", height: "60vh"}}>
                                            <em className="fas fa-circle-notch fa-spin fa-2x" style={{color:"#91867d"}}></em>
                                        </div>
                                    :
                                        <div className="padding-10">
                                            <div className="input-group" style={{flexDirection:"column"}}>
                                                <label>Escolha o serviço</label>
                                                <SelectGroup 
                                                    name="service" 
                                                    id="service" 
                                                    placeholder="Selecione" 
                                                    required
                                                    errorMessage={{ required: "Por favor, informe um serviço!" }}
                                                    className="form-control "
                                                    value={service} 
                                                    onChange={e => handleService(e.target.value,e.target.options[e.target.selectedIndex].text)}>
                                                        <option value="">Selecione</option>
                                                        {tipo.map((_item) => {
                                                            return (<option value={_item.value}>{_item.label}</option>)
                                                        })}
                                                </SelectGroup> 
                                            </div>
                                        </div>
                                    }
                                </TabPane>
                                <TabPane id="tabPane2" tabId={2}>
                                    <div className="padding-10">
                                        <CalendarioAgendamento callback={handleDate} nextCallback={setStep} originalDate={setOriginalDate}/>
                                    </div>
                                </TabPane>
                                <TabPane id="tabPane3" tabId={3}>
                                    <div className="padding-10">
                                        {dataHours.length <= 0 ? 
                                            <div className="bg-warning" style={{display:"flex",justifyContent:"center",padding:"10px", borderRadius:"5px"}}>
                                                <span>Não existem horários disponíveis para o serviço e a data selecionada.</span>
                                            </div>
                                        :
                                            <div className={`padding-10 ${dataHours.length <= 0 ? ' hidden' : '' }`}>
                                                <div className="input-group" style={{flexDirection:"column"}}>
                                                    <label>Escolha o horário</label>
                                                    <SelectGroup 
                                                        name="hour" 
                                                        id="hour" 
                                                        placeholder="Selecione" 
                                                        required
                                                        errorMessage={{ required: "Por favor, informe um horário!" }}
                                                        className="form-control "
                                                        value={hour} 
                                                        onChange={e => setHour(e.target.value)}>
                                                        <option value="">Selecione</option> 
                                                        {dataHours.map((item, key) => {
                                                            return(<option value={item.hour} key={key}>{item.hour}</option>)
                                                        })}
                                                    </SelectGroup> 
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </TabPane>
                                <TabPane id="tabPane4" tabId={4}>
                                    <div className="padding-10">
                                        <div>
                                            <label>Nome</label>
                                            <div className="input-group">
                                                <TextInput  
                                                    name="name"
                                                    id="name"
                                                    type="text"
                                                    required
                                                    autoFocus={true}
                                                    maxLength={100}
                                                    errorMessage={{ required: "Por favor, informe um nome!" }}
                                                    value={name}
                                                    className="form-control "
                                                    onChange={e => setName(e.target.value)}
                                                    autoComplete="none"/>
                                            </div>
                                        </div>
                                        <div>
                                            <label>Whatsapp</label>
                                            <div className="input-group">
                                                    <MaskWithValidation
                                                        name="phone"
                                                        id="phone"
                                                        maxLength={25}
                                                        value={phone}
                                                        required
                                                        errorMessage={{ required: "Por favor, informe um telefone!" }}
                                                        className="form-control"
                                                        onChange={e =>  setPhone(e.target.value)}
                                                        autoComplete="none"
                                                        mask={['(',/[0-9]/,/[0-9]/,')',' ',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,'-',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/]}/>
                                            </div>
                                        </div>
                                        <div>
                                            <label>Email</label>
                                            <div className="input-group">
                                                <TextInput  
                                                    name="email"
                                                    id="email"
                                                    type="text"
                                                    autoFocus={true}
                                                    maxLength={100}
                                                    value={email}
                                                    className="form-control "
                                                    onChange={e => setEmail(e.target.value)}
                                                    autoComplete="none"/>
                                            </div>
                                        </div>  
                                        <div>
                                            <label>Observação</label>
                                            <div className="input-group">
                                                <TextInput  
                                                    name="observacao"
                                                    id="observacao"
                                                    type="text"
                                                    autoFocus={true}
                                                    maxLength={100}
                                                    value={observacao}
                                                    multiline 
                                                    className="form-control "
                                                    onChange={e => setObservacao(e.target.value)}
                                                    autoComplete="none"/>
                                            </div>
                                        </div> 
                                    </div>
                                </TabPane>
                                <TabPane id="tabPane4" tabId={5}>
                                    <div className="padding-10" style={{display:"flex", justifyContent:"center", flexDirection:"column", alignItems:"center"}}>
                                        <div className="box-comprovante" style={{backgroundColor:"#f2eecb", padding:"10px", borderRadius:"5px"}}>
                                            <div style={{display:"flex", justifyContent:"center"}}>
                                                <img src={LOGO_CA} style={{width:"80%"}} alt="logo"/>
                                            </div>
                                            <div style={{borderTop:"1px dashed #cacaca", marginTop:"10px"}}>
                                            </div>
                                            <div style={{display:"flex", justifyContent:"center", alignItems:"center", marginTop:"10px"}}>
                                                <span style={{fontWeight:"bold", fontSize:"16px"}}>COMPROVANTE DE AGENDAMENTO</span>
                                            </div>
                                    
                                            <div style={{borderTop:"1px dashed #cacaca", marginTop:"10px"}}>
                                            </div>
                                            <div>
                                                <div style={{display:"flex", justifyContent:"center"}}>
                                                    <span style={{fontWeight:"bold"}}>Dados de Cadastro</span>
                                                </div>
                                                <div>
                                                    <div>
                                                        <span style={{fontWeight:"bold"}}>Nome: </span><span>{name.toUpperCase()}</span>
                                                    </div>            
                                                    <div>
                                                        <span style={{fontWeight:"bold"}}>Telefone: </span><span> {phone}</span>
                                                    </div> 
                                                    <div>
                                                        <span style={{fontWeight:"bold"}}>Email: </span><span>{email.toLowerCase()}</span>
                                                    </div> 
                                                    <div>
                                                        <span style={{fontWeight:"bold"}}>Observação: </span><span> {observacao.toUpperCase()}</span>
                                                    </div> 
                                                </div>
                                            </div>
                                            <div style={{borderTop:"1px dashed #cacaca", marginTop:"10px"}}></div>
                                            <div>
                                                <div style={{display:"flex", justifyContent:"center"}}>
                                                    <span style={{fontWeight:"bold"}}>Informações do Agendamento</span>
                                                </div>
                                                <div>
                                                    <div>
                                                        <span style={{fontWeight:"bold"}}>Serviço: </span><span> {description}</span>
                                                    </div>    
                                                    <div>
                                                        <span style={{fontWeight:"bold"}}>Data: </span><span> {dateFormat}</span>
                                                    </div> 
                                                    <div>
                                                        <span style={{fontWeight:"bold"}}>Horário: </span><span> {hour}</span>
                                                    </div> 

                                                </div>
                                            </div>
                                            <div style={{borderTop:"1px dashed #cacaca", marginTop:"10px"}}></div>
                                            <div>
                                                <div style={{display:"flex", justifyContent:"center"}}>
                                                    <span style={{fontWeight:"bold"}}>Observações</span>
                                                </div>
                                                <div>
                                                    <span>Favor chegar com 10 minutos de antecedência ao horário agendado para inicialização do procedimento.</span>
                                                </div>
                                            </div>
                                            <div style={{borderTop:"1px dashed #cacaca", marginTop:"25px"}}></div>
                                            <div style={{padding:"10px", justifyContent:"center", alignItems:"center", display:"flex"}}>
                                                <QRCode value="https://www.cartorioantoniodoprado.com.br/" bgColor="#f2eecb"/>
                                            </div>
                                            <div style={{borderTop:"1px dashed #cacaca", marginTop:"25px"}}></div>
                                            <div style={{padding:"10px", justifyContent:"center", alignItems:"center", display:"flex"}}>
                                                <span>O Cartório Antônio do Prado agradece pela preferência.</span>
                                            </div> 
                                            <div style={{padding:"10px", justifyContent:"center", alignItems:"center", display:"flex",marginTop:"10px"}}>
                                                <span>Desenvolvido por <span style={{fontWeight:'bold'}}>VIROMA TECNOLOGIA</span></span>
                                            </div>                     
                                        </div>
                                    </div>
                                </TabPane>
                            </TabContent>
                            <div style={{padding:"15px", display:"flex", justifyContent:"flex-end"}}>
                                {step > 1 && step <= 4? 
                                    <button type="button" className='btn btn-theme' onClick={e => {handleStep(step - 1, true)}}><i className="fa fa-arrow-left"/>Anterior</button>
                                 : ''}
                                {step < 5 ?
                                    loadingPost ?
                                        <button type="button" disabled className='btn btn-theme' style={{marginLeft:"5px"}}><i className="fas fa-circle-notch fa-spin"/> Aguarde...</button>
                                                :
                                        <button type="submit"className='btn btn-theme' style={{marginLeft:"5px"}} onClick={e => {handleStep(step + 1,false)}}>Próximo <i className="fa fa-arrow-right"/></button>
                                : ''}
                            </div>
                        </CardBody>
                    </Card>
                </ValidationForm>

                <div id="dv-comprovante" className="padding-10" style={{display:"flex", justifyContent:"center", flexDirection:"column", alignItems:"center", marginTop:"5000px"}}>
                    <div ref={ref} className="box-comprovante" style={{backgroundColor:"#f2eecb", padding:"10px", borderRadius:"5px"}}>
                        <div style={{display:"flex", justifyContent:"center"}}>
                            <img src={LOGO_CA} style={{width:"80%"}} alt="logo"/>
                        </div>
                        <div style={{borderTop:"1px dashed #cacaca", marginTop:"10px"}}></div>
                        <div style={{display:"flex", justifyContent:"center", alignItems:"center", marginTop:"10px"}}>
                            <span style={{fontWeight:"bold", fontSize:"16px"}}>COMPROVANTE DE AGENDAMENTO</span>
                        </div>
                        <div style={{borderTop:"1px dashed #cacaca", marginTop:"10px"}}></div>
                        <div>
                            <div style={{display:"flex", justifyContent:"center"}}>
                                <span style={{fontWeight:"bold"}}>Dados de Cadastro</span>
                            </div>
                            <div>
                                <div>
                                    <span style={{fontWeight:"bold"}}>Nome: </span><span>{name.toUpperCase()}</span>
                                </div>            
                                <div>
                                    <span style={{fontWeight:"bold"}}>Telefone: </span><span> {phone}</span>
                                </div> 
                                <div>
                                    <span style={{fontWeight:"bold"}}>Email: </span><span>{email.toLowerCase()}</span>
                                </div> 
                                <div>
                                    <span style={{fontWeight:"bold"}}>Observação: </span><span> {observacao.toUpperCase()}</span>
                                </div> 
                            </div>
                        </div>
                        <div style={{borderTop:"1px dashed #cacaca", marginTop:"10px"}}></div>
                        <div>
                            <div style={{display:"flex", justifyContent:"center"}}>
                                <span style={{fontWeight:"bold"}}>Informações do Agendamento</span>
                            </div>
                            <div>
                                <div>
                                    <span style={{fontWeight:"bold"}}>Serviço: </span><span> {description}</span>
                                </div>    
                                <div>
                                    <span style={{fontWeight:"bold"}}>Data: </span><span> {dateFormat}</span>
                                </div> 
                                <div>
                                    <span style={{fontWeight:"bold"}}>Horário: </span><span> {hour}</span>
                                </div> 
                            </div>
                        </div>
                        <div style={{borderTop:"1px dashed #cacaca", marginTop:"10px"}}></div>
                        <div>
                            <div style={{display:"flex", justifyContent:"center"}}>
                                <span style={{fontWeight:"bold"}}>Observações</span>
                            </div>
                            <div>
                                <span>Favor chegar com 10 minutos de antecedência ao horário agendado para inicialização do procedimento.</span>
                            </div>
                        </div>
                        <div style={{borderTop:"1px dashed #cacaca", marginTop:"25px"}}></div>
                        <div style={{padding:"10px", justifyContent:"center", alignItems:"center", display:"flex"}}>
                            <QRCode value="https://www.cartorioantoniodoprado.com.br/" bgColor="#f2eecb"/>
                        </div>
                        <div style={{borderTop:"1px dashed #cacaca", marginTop:"25px"}}></div>
                        <div style={{padding:"10px", justifyContent:"center", alignItems:"center", display:"flex"}}>
                            <span>O Cartório Antônio do Prado agradece pela preferência.</span>
                        </div> 
                        <div style={{padding:"10px", justifyContent:"center", alignItems:"center", display:"flex",marginTop:"10px"}}>
                            <span>Desenvolvido por <span style={{fontWeight:'bold'}}>VIROMA TECNOLOGIA</span></span>
                        </div>                    
                    </div>
                </div>
            </div>            
        );
    
}


