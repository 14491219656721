import React from 'react'
import Routes  from './routes'

import './styles/app.scss'

function App() {
  return (<Routes/>)
}

export default App;
