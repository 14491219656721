import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { TransitionGroup } from 'react-transition-group';

import Agendamento    from './pages/maintenance/agendamento'
import Error404       from './pages/errors/404'

const Routes = () => {
    return (
        <TransitionGroup>
            <BrowserRouter>
                <Switch >
                    <Route exact path="/" component={Agendamento} />
                    <Route path="/*" component={Error404} />
                </Switch>
            </BrowserRouter>
        </TransitionGroup>
    )
}

export default Routes;