import { create } from 'apisauce'

const api = create({
    baseURL : process.env.REACT_APP_IP_BACKEND + ':' + process.env.REACT_APP_PORT_BACKEND,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "authorization": 'f94afd32-44b7-44de-a720-cb407f4190b8'
    }
})



export default api