import React, {useState, useEffect} from 'react'

import { isEqual, format  } from 'date-fns'

import FullCalendar from '@fullcalendar/react';
import adaptivePlugin from '@fullcalendar/adaptive';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import esLocale from '@fullcalendar/core/locales/pt-br';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import '@fortawesome/fontawesome-free/css/all.css';
import { NotificationManager } from 'react-notifications';

export default function CalendarioAgendamento( {callback, nextCallback, originalDate}) {
    const [startDate, setStartDate]                        = useState(null)
    const [events, setEvents]                              = useState([])

    const calendarRef = React.createRef()
    const renderEventContent = (eventInfo) => {
        return (
          <>
            <div className="fc-event-title fc-sticky">
                {eventInfo.event.title}
            </div>            
          </>
        )
    }

    const newShedulling = (e) => {
        if (format(new Date(e.start),'yyyy-MM-dd') < format(new Date(),'yyyy-MM-dd')) {
            NotificationManager.error('A data informada e menor que a data atual.', 'Erro!', 5000)
            setEvents([{
                id    : '1',
                start : format(new Date(),"yyyy-MM-dd") 
            }])
            callback(format(new Date(),"yyyy-MM-dd"),null)
            originalDate(new Date())
        } else {
            setEvents([{
                id    : '1',
                start : format(e.start,"yyyy-MM-dd") 
            }])
            callback(format(e.start,"yyyy-MM-dd"),null)
            originalDate(new Date(e.start))
            nextCallback(3)
        }
        
    }

    useEffect(() => {
   
    },[])

    return (
        <FullCalendar 
            ref={calendarRef}
            id="fullCalendar"
            defaultView="dayGridMonth"
            headerToolbar={{
                left: 'prev,next',
                center: 'title',
                right: ''
            }}
        
            initialView='dayGridMonth'
            editable={true}
            selectable={true}
            selectHelper={true}
            longPressDelay={1}
            themeSystem= 'bootstrap'
            locale={esLocale}
            allDaySlot={false}
            firstDay={1}
            aspectRatio={2}
            eventContent={renderEventContent} // custom render function
            select={e => newShedulling(e)}
            plugins={[dayGridPlugin, interactionPlugin,bootstrapPlugin, adaptivePlugin, listPlugin]}
            events={events}
            schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
            datesSet={(arg) => {
                if (!isEqual(startDate,new Date(arg.view.currentStart))) {                     
                    setStartDate(new Date(arg.view.currentStart))
                }
            }}
        />
    )
}